// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary_black: #000000;
    --primary_white: #FCFCFC;
    --primary_blue: #005377;
    --accent_blue: #00A7E1;
    --accent_cream: #FAF0CA;

    --action_orange: #FFA500;
    --action_orange_highlighted: #F59F00;
}`, "",{"version":3,"sources":["webpack://./src/palette.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;IACtB,uBAAuB;;IAEvB,wBAAwB;IACxB,oCAAoC;AACxC","sourcesContent":[":root {\r\n    --primary_black: #000000;\r\n    --primary_white: #FCFCFC;\r\n    --primary_blue: #005377;\r\n    --accent_blue: #00A7E1;\r\n    --accent_cream: #FAF0CA;\r\n\r\n    --action_orange: #FFA500;\r\n    --action_orange_highlighted: #F59F00;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
