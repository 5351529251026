import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    style={{ color: 'black', backgroundColor: 'white', padding: '12px 24px', borderRadius: '5px', textDecorationLine: 'none'}}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||  child.props.children.toString().toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);


const DropdownFilterMenu = ({options, setValue}) => {

  const [title, setTitle] = useState(options ? options[0] : 'Select...')
  const [data, setData] = useState(options ? options : [])

  return(
      <Dropdown style={{
          display: 'inline-block',
          position: 'relative',
          width: '100%',
          marginBottom: '10px',
          borderRadius: '5px',
          backgroundColor: 'white',
          color: 'black',
          padding: '12px 24px',
          border: '1px solid #ced4da',
          borderRadius: '5px',
          cursor: 'pointer'
      
      }}>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {title}
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu} style={{
            maxHeight: '60vh',
            overflowY: 'scroll',
            alignContent: 'flex-start',
            justifyContent: 'flex-start',
            padding: '0px',
            transform: 'translate3d(0px, 38px, 0px) !important',
          }}>
              {(data != null || data != undefined) && data.map((item, index) => {
                  return <Dropdown.Item 
                  key={index} 
                  eventKey={index} 
                  onClick={()=>{
                    setTitle(item); 
                    setValue(item);
                  }}>
                    {item}
                  </Dropdown.Item>;
              })}
          </Dropdown.Menu>
      </Dropdown>
  )
};

export default DropdownFilterMenu;