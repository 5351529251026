import { useState, useEffect, forwardRef, useContext, useImperativeHandle } from 'react';
import { GetYears, GetVehicleData, GetParts, GetManfacturers } from '../Getters.js';
import { SearchBarStyles as styles } from '../../styles.js';
import Form from 'react-bootstrap/Form';
import { searchStage } from '../../App.js';
import DropdownFilterMenu from './DropdownFilterMenu.js';

const Searchbar = forwardRef(({ }, ref) => {
    const [manufacturers, setManufacturers] = useState([]);
    const [models, setModels] = useState([]);
    const [makes, setMakes] = useState([]);
    const [parts, setParts] = useState([]);

    const [splitDataObject, setSplitDataObject] = useState({}); //Object that contains the split data of the vehicle names

    //Search Variables
    const [year, setYear] = useState("");

    const [make, setMake] = useState(""); //Make is the id of the vehicle
    const [model, setModel] = useState("");  //model is the model of the vehicle (second part of the name)
    const [manufacturer, setManufacturer] = useState(""); //Manufacturer is the manufacturer of the vehicle (first part of the name)

    const [part, setPart] = useState("");
    const [maxDistance, setMaxDistance] = useState(0);

    ref.current = {
        GetSearchValues: () => {
            const search = {
                year: year,
                make: make, //set this to be the number value (id) of the make
                part: part,
                distance: maxDistance,
                user_id: '',
            }
            return search;
        },
    }

    useEffect(() => {
        async function InitilaizeSearchOptions() {
            const all_makes = await GetVehicleData();
            //console.log("All Makes: ", all_makes)
            let first = await GetParts({ from: 0, to: 1000 })
            // let second = await GetParts({from: 1001, to: 2000})
            // let third = await GetParts({from: 2001, to: 3000})
            // let fourth = await GetParts({from: 3001, to: 4000})
            const all_parts = first

            setMakes(all_makes);
            setParts(all_parts);


            const split_data = all_makes.map((item) => {
                const splitName = item.name.split(' - ');

                //  object includes  the split name and the ID
                return {
                    id: item.id,
                    splitName: splitName,
                };
            });

            //console.log("Split Data: ", split_data)

            let modelsByManufacturer = {};

            split_data.forEach(item => {
                let manufacturer = item.splitName[0];
                let model = item.splitName[1];
                let id = item.id;

                // Check if the manufacturer already exists in the structure
                if (!modelsByManufacturer.hasOwnProperty(manufacturer)) {
                    modelsByManufacturer[manufacturer] = [];
                }

                // Add the model and its ID under the manufacturer
                modelsByManufacturer[manufacturer].push({ model: model, id: id });
            });

            //console.log(modelsByManufacturer);
            //each manufacturer contains an array of objects with the model and id of the model. 
            setSplitDataObject(modelsByManufacturer);
        }

        InitilaizeSearchOptions();
    }, []);


    //#region Logo State Control System
    const [stage, setStage] = useContext(searchStage);
    function CalculateStage() {
        let newStage = 0;
        if (year !== '') newStage++;
        if (make !== '') newStage++;
        if (part !== '') newStage++;
        if (maxDistance !== 0) newStage++;
        setStage(newStage);
    }
    useEffect(() => {
        CalculateStage();
    }, [year, make, part, maxDistance]);
    //#endregion

    return (
        <header style={styles.dropdownHeader}>
            <Form action="/action_page.php" style={styles.searchForm}>
                <Form.Select
                    name="Year"
                    id="year"
                    style={styles.dropdown}
                    onChange={(e) => {
                        setYear(e.target.value)
                    }} //PUT ONCHANGE HERE NOT IN OPTIONS - FOR CHROME FIX
                >
                    <option key={'yearNull'} value={''}>{'Select Year...'}</option>
                    {GetYears().map((item) => {
                        return <option key={item} value={item}>{item}</option>;
                    })}
                </Form.Select>


                <Form.Select
                    name="Manufacturer"
                    id="manufacturer"
                    style={styles.dropdown}
                    onChange={(e) => {
                        const selectedManufacturer = e.target.value;

                        if (selectedManufacturer === '') {
                            setMake('');
                        }
                        setManufacturer(selectedManufacturer ? selectedManufacturer : '');
                        setModel('');
                    }}
                >
                    <option key={'makeNull'} value={''}>{'Select Manufacturer...'}</option>
                    {Object.keys(splitDataObject).map((manufacturer) => {
                        return <option key={manufacturer} value={manufacturer}>{manufacturer}</option>;
                    })}
                </Form.Select>

                {manufacturer && (
                    <Form.Select
                        name="Model"
                        id="model"
                        style={styles.dropdown}
                        onChange={(e) => {
                            const modelName = e.target.value;
                            const modelObj = splitDataObject[manufacturer].find(model => model.model === modelName);
                            //Set the make  to the found models id
                            setMake(modelObj ? modelObj.id : '');
                        }}
                    >
                        <option key={'modelNull'} value={''}>Select Model...</option>
                        {manufacturer && splitDataObject[manufacturer] ?
                            splitDataObject[manufacturer].map((item) => (
                                <option key={item.id} value={item.model}>{item.model}</option>
                            )) : null
                        }
                    </Form.Select>
                )}

                <Form.Select
                    name="Part"
                    id="part"
                    style={styles.dropdown}
                    onChange={(e) => {
                        const selectedPart = parts.find(part => part.part_name === e.target.value);
                        setPart(selectedPart ? selectedPart.id : '');
                    }}
                >
                    <option key={'partNull'} value={''}>{'Select Part...'}</option>
                    {parts.map((item) => {
                        return <option key={item.part_name} value={item.part_name}>{item.part_name}</option>;
                    })}
                </Form.Select>
                {/* <Form.Select
                    name="Distance"
                    id="distance"
                    style={styles.dropdown}
                    onChange={(e) => {

                        switch (e.target.value) {
                            case "< 50 km":
                                setMaxDistance(50000);
                                break;
                            case "< 100 km":
                                setMaxDistance(100000);
                                break;
                            case "< 200 km":
                                setMaxDistance(200000);
                                break;
                            default:
                                setMaxDistance(0); // Any Distance
                        }
                    }}
                >
                    <option key={'any'} value={"any"}>{"Any Distance"}</option>
                    <option key={'< 50km'} value={"< 50 km"}>{"< 50 km"}</option>
                    <option key={'50 - 100 km'} value={"< 100 km"}>{"< 100 km"}</option>
                    <option key={'< 200 km'} value={"< 200 km"}>{"< 200 km"}</option>
                </Form.Select> */}
            </Form>
        </header>
    )
});



export default Searchbar;
